import React, { useContext } from "react";

import { Container, Row, Col, Card, Modal, Button } from "reactstrap";
import SpeakerModal from "components/SpeakerModal.js";
import Slider from "react-slick";
import ShowMoreText from "react-show-more-text";
import { DataContext } from "DataContainer";

function SpeakerGrid() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  const { speakers } = useContext(DataContext);

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto p-3"
          style={{
            borderRadius: 0,
            boxShadow: "none",
            background: "transparent",
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <img
                  src={speaker.image || require("assets/speakers/placeholder.jpg")}
                  alt="speaker"
                  width="100%"
                  className="rounded-circle p-2"
                  style={{ border: "2px #20bdbe dashed" }}
                />
              </Col>

              <Col xs={12} className="px-0">
                <div className="text-center text-white">
                  <h4 className="text-700 mt-2 mb-0 text-g d-block px-0">
                    {speaker.highlightText}
                  </h4>
                  <p className="text-700 mt-2 mb-0 d-block px-0 ">{speaker.name}</p>
                  <p className="text-300 m-0">{speaker.title}</p>
                  <p
                    className="text-400 m-0 d-block px-0"
                    // style={{ fontSize: "16px" }}
                  >
                    {speaker.company}, {speaker.country}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
        }}
        className="section py-5"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1
                className="text-center text-g"
                style={{
                  fontSize: "3rem",
                }}
              >
                OUR SPEAKERS AND PANELISTS
              </h1>
            </Col>
          </Row>

          <Row className="py-4">
            {size ? (
              speakers.map((speaker, index) => (
                <Col className="mx-auto px-0" lg={3} xs={12} key={index}>
                  <SpeakerCard speaker={speaker} />
                </Col>
              ))
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
          <Row className="justify-content-center">
            <Col lg={3} className="text-center px-1">
              <Button
                href="/register"
                className="rounded-0 text-400 text-white"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  Register Now
                </span>
              </Button>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)}>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => updateModal(e)}
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#888888",
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <SpeakerModal speaker={open.speaker} />
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;

export const speakers1 = [
  {
    name: "Dr Anas Bataw",
    sTitle: "Event Chairman",
    title: "Director",
    company: "Heriot-Watt University, Centre of Excellence in Smart Construction (CESC), UAE",
    image: "anas.jpg",
    text: [``],
  },
  // {
  //   name: "Rahul Shah",
  //   sTitle: "Workshop Leader",
  //   title: "Director - Built Environment",
  //   company: "BSI, UK",
  //   image: "rahul.png",
  //   text: [
  //     `Rahul Shah is a Sector Development Director at British Standards Institution (BSI). Rahul has worked with asset owners, main contractors, architects, engineers and manufacturers for over 23 years and has held leadership roles responsible for driving digital transformation, innovation, and leading on BIM strategy and implementation programmes globally. During his career Rahul has gained experience of implementing various digital initiatives on a diverse range of projects, including large scale multi-billion-pound urban regeneration schemes through to hospitals, airports, residential, commercial, data centres, mixed-use public spaces, stadiums and metros. He has gained thorough understanding of the risks and opportunities faced by today’s global construction businesses while going through digitalization.

  //   In his current role at BSI Rahul drives digital transformation agenda in the built environment globally and supports clients in Digital Trust, Innovation, BIM, Digital Twin, Smart Cities, IoT, and Information Security and Resilience. `,
  //   ],
  // },
  {
    name: "Eng. Aaesha Shehhi",
    title: "Director - GIS Center",
    company: "RAK Municipality, UAE ",
    image: "Aaesha Shehhi.png",
    text: [``],
  },
  {
    name: "David Glennon",
    title: "Director of Digital Delivery",
    company: "The Red Sea Development Co, Saudi Arabia",
    image: "David Glennon.jpg",
    text: [
      `David Glennon is the Senior Digital Delivery Director for The Red Sea Development Company (TRSDC) , a closed joint stock company wholly owned by the Public Investment Fund (PIF), Saudi Arabia’s sovereign wealth fund. He oversees the development and implementation of new and innovative digital delivery techniques for a new international luxury tourism destination set along the Red Sea as part of the Kingdom’s Vision 2030. 

    Bringing more than 25 years’ experience in the construction industry, he oversees the use of pioneering technology to improve the design and construction process, introducing automation and data driven insights to deliver fast-track construction, design for manufacture and assembly, and enabling the Smart Destination strategy through a ‘Digital Twin’ to enhance customer experience. 
    
    Previous positions include Managing Director of DoubleD.io, a digital accelerator for the built environment, and Head of Digital for Arcadis in the UK, leading their digital programme and the delivery of platforms, innovation, data and analytics and ecosystem thinking. As Director of Digital Project Delivery at AECOM, he was responsible for creating and implementing digital strategy across Europe, the Middle East, India and Africa. Prior to this, David held senior positions at Mott MacDonald and Lend Lease. `,
    ],
  },
  {
    name: "Menno de Jonge",
    title: "Director of Digital Delivery, Design and Construction",
    company: "NEOM, Saudi Arabia",
    image: "menno.jpg",
    text: [``],
  },
  {
    name: "Andrew Rippon",
    title: "Smart City Director- County Zoning & Planning Department",
    company: "Royal Commission for AlUla, Saudi Arabia",
    image: "andrew.jpg",
    text: [``],
  },
  // {
  //   name: "Carl Siddons",
  //   title: "Senior Vice President - Digital Design",
  //   company: "ALDAR, UAE",
  //   image: "placeholder.jpg",
  //   text: [``],
  // },
  {
    name: "Michal Lakomski",
    title: "Mayor's Proxy for Smart City, Head of Digitalization and Cybersecurity Office",
    company: "Smart City Poznan, Poland",
    image: "Michal Lakomski.jpg",
    text: [``],
  },
  {
    name: "Jarmo Suomitso",
    title: "Helsinki 3D+ Project Manager/Architect",
    company: "City of Helsinki/City Executive Office, Finland",
    image: "Jarmo Suomisto.jpg",
    text: [``],
  },

  {
    name: "Dan Isaacs",
    title: "CTO",
    company: "Digital Twin Consortium, USA",
    image: "Dan Isaacs.jpg",
    text: [``],
  },
  {
    name: "Paul Doherty",
    title: "President & CEO",
    company: "The Digit Group Inc., USA",
    image: "paul.jpg",
    text: [
      `Paul is President and CEO of The Digit Group, inc. (TDG), a Singapore-registered Smart City real estate development and investment company.  As seen on Bloomberg TV, acknowledged by CNBC as one of America's Business Titans and reported by Forbes as “Changing the World”, Paul is an award-winning architect. a Senior Fellow of the Design Futures Council and a Fellow of the International Facility Management Association (IFMA).  Concurrently, Paul is the co-founder and producer of the critically acclaimed AEC Hackathon (www.aechackathon.com).  Paul’s current work includes the design, planning, construction, and operations of Smart Cities around the world.`,
    ],
  },
  {
    name: "Prakash Senghani",
    title: "Director",
    company: "Head of Digital Delivery, JLL, UAE",
    image: "prakash.png",
    text: [``],
  },

  {
    name: " Naji Atallah",
    title: "Head of Construction & Manufacturing",
    company: "Autodesk, UAE",
    image: "naji.jpg",
    text: [``],
  },
  {
    name: " Jacques BELTRAN",
    title: "Vice-President Public Services",
    company: "Dassault Systemes, France",
    image: "beltran.jpg",
    text: [``],
  },

  {
    name: "Gary Furphy",
    title: "Digital Engineering Manager and Solutions & Technology - Digital Delivery Lead",
    company: "Jacobs, UAE",
    image: "gary.jpg",
    text: [``],
  },
  {
    name: "Arsen Safaryan",
    title: "Director - Information & Data Management, Development ",
    company: "Saudi Entertainment Ventures, Saudi Arabia",
    image: "Arsen Safaryan.jpg",
    text: [``],
  },

  {
    name: "James Frampton",
    title: "Middle East Director",
    company: "SoftwareONE | MTWO Construction Cloud, UAE",
    image: "James Frampton_DT.jpg",
    text: [``],
  },
  // {
  //   name: "Bart Brink",
  //   title: "Global Program Director Digital Twin,",
  //   company: "Royal HaskoningDHV, Netherlands",
  //   image: "Bart Brink.jpg",
  //   text: [``],
  // },

  {
    name: "Anthony Tuffuor",
    title: "Director - Global Lead GIS and Digital Twins",
    company: "BuroHappold Engineering | Cities, UK",
    image: "AnthonyTuffour1.jpg",
    text: [``],
  },
  {
    name: "Steven Velegrinis",
    title: "Director Urbanism & Planning, MEA",
    company: "AECOM, UAE",
    image: "Steven Velegrinis.jpg",
    text: [``],
  },

  {
    name: "May Winfield",
    title: "Global Director of Commercial, Legal and Digital Risks",
    company: "Buro Happold, UK",
    image: "may.jpg",
    text: [``],
  },
  {
    name: "Wissam Hijazi",
    title: "Director VDC-Middle East",
    company: "EllisDon, UAE",
    image: "wissam.jpg",
    text: [``],
  },

  // {
  //   name: "George Mokhtar",
  //   title:
  //     "Director, Technology - Digital Advisory | Technology Solutions & Security | Data & Analytics",
  //   company: "Turner & Townsend, UK",
  //   image: "George Mokhtar_Turner Townsend.png",
  //   text: [``],
  // },
  {
    name: "Juan Tena Florez",
    title: "Regional Digital Design Manager",
    company: "KEO Consultants, UAE",
    image: "Juan Florez.jpg",
    text: [``],
  },
  {
    name: "Shilpa Mistry-McCallum",
    title: "Middle East Digital Lead",
    company: "Mott MacDonald, UAE",
    image: "Shilpa Mistry-McCallum Picture.jpg",
    text: [``],
  },
  {
    name: " Mohannad Salam",
    title: "Regional Lead-Smart Cities & Digital Twin",
    company: "ATKINS, UAE",
    image: "Mohannad Salam.png",
    text: [``],
  },

  {
    name: " Nizar Jegham",
    title: "Advisory Director – Digital, Middle East",
    company: "WSP, UAE",
    image: "Nizar Jegham.png",
    text: [``],
  },

  {
    name: "Dr Marwan AbuEbeid",
    title: "Digital Transformation Lead",
    company: "Turner Construction – International LLC, UAE",
    image: "Marwan Abuabeid.jpg",
    text: [``],
  },
  {
    name: "Dr Mehmet Sakin",
    title: "President – BIM4TURKEY",
    company: "Manager PMO, Kalyon Holding, Turke",
    image: "Mehmet Sakin_DT.jpg",
    text: [``],
  },
  {
    name: "Wajdi Mereb",
    title: "Senior Manager BIM – Development Design Dept.",
    company: "MIRAL, UAE",
    image: "Wajdi Mereb (1).jpeg",
    text: [
      `Currently, Wajdi is managing the Digital Twin Initiative & BIM implementation in multi-billion’s projects in one of the most reputable governmental entities in UAE- Miral Asset Management- that has a clear strategy for digital transformation targeting to become a fully  integrated data-driven asset owner. Hence, paving the way to translate 4th Industrial Revolution into reality & integrated with its ecosystem to deliver smart & agile solutions for Leisure & Entertainment industry in Yas Island & beyond.  

      Previously, he initiated suites of smart & intelligent objectives that had been translated into a portfolio of projects in Dubai RTA, which became a regional benchmarking reference that led to a market culture change as far as digital transformation phenomena is concerned, in addition he led RTA to be a key partner in developing BSI’s fundamental BIM Certification schemes “ISO 19650 & UK BIM Level 2”, together with obtaining their certificates as the 1st organization worldwide. Also he managed a successful BIM implementation in multi-billion strategic infrastructure projects “e.g. Metro Route 2020 project- $ 3 billion value” that contributed directly to winning the EXPO 2020 file for UAE. 
      
      `,
    ],
  },
  // {
  //   name: " Dr Jacques Khouri",
  //   title:
  //     "Executive Board Member, Digital Chapter - Society of Engineers – UAE | Senior Technology Advisor - Ministry of Energy & Infrastructure",
  //   company: "MOEI - UAE",
  //   image: "Photo Dr. Jacques El Khouri.jpg",
  //   text: [``],
  // },
  {
    name: "Lindsey Malcolm",
    title: "Associate Director of Sustainability",
    company: "AESG, UAE",
    image: "Lindsey Malcolm.png",
    text: [``],
  },
  {
    name: "Ahmed AbouAlfa",
    title: "Executive Board Member",
    company: "Society of Engineers – Digital Chapter, Saudi Arabia",
    image: "Ahmed Abou Alfa.jpg",
    text: [``],
  },
  {
    name: "Edward Hartung",
    title: "Sales Director – MEA",
    company: "Archdesk, UAE",
    image: "Edward Hartung.jpg",
    text: [``],
  },
  {
    name: "Matt Piper",
    title: "Global Director of Industry Solutions, Utilities & AEC",
    company: "ESRI, USA",
    image: "Matt Piper_2019.jpg",
    text: [``],
  },
  {
    name: "Dana Kawas",
    title: "CEO",
    company: "Thynkli, Canada",
    image: "Dana Kawas.png",
    text: [``],
  },
  {
    name: "Tony Abou Jawad",
    title: "Industry Territory Manager",
    company: "Autodesk, UAE",
    image: "Tony-abou jawad.png",
    text: [``],
  },
];
